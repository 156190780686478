import type { RouteConfig } from 'vue-router';

const routes = (defaultRoute: string): Array<RouteConfig> => [
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "adminroutes" */ '../ui/views/Admin/Auth.vue'),
    meta: {
      layout: 'AdminAuth',
      public: true,
    },
  },
  {
    path: '/users',
    component: () => import(/* webpackChunkName: "adminroutes" */ '../ui/views/Admin/Users.vue'),
    meta: {
      layout: 'Admin',
    },
  },
  {
    path: '/users/:id?',
    component: () =>
      import(/* webpackChunkName: "adminroutes" */ '../ui/views/Admin/UsersDetail.vue'),
    meta: {
      layout: 'Admin',
    },
  },
  {
    path: '/age-verifications',
    component: () =>
      import(/* webpackChunkName: "adminroutes" */ '../ui/views/Admin/AgeVerifications.vue'),
    meta: {
      layout: 'Admin',
    },
  },
  {
    path: '/age-verification/:id?',
    component: () =>
      import(/* webpackChunkName: "adminroutes" */ '../ui/views/Admin/AgeVerificationDetail.vue'),
    meta: {
      layout: 'Admin',
    },
  },
  {
    path: '/models',
    component: () => import(/* webpackChunkName: "adminroutes" */ '../ui/views/Admin/Models.vue'),
    meta: {
      layout: 'Admin',
    },
  },
  {
    path: '/categories',
    component: () =>
      import(/* webpackChunkName: "adminroutes" */ '../ui/views/Admin/Categories.vue'),
    meta: {
      layout: 'Admin',
    },
  },
  {
    path: '/products',
    component: () => import(/* webpackChunkName: "adminroutes" */ '../ui/views/Admin/Products.vue'),
    meta: {
      layout: 'Admin',
    },
  },
  {
    path: '/products/:id?',
    component: () =>
      import(/* webpackChunkName: "adminroutes" */ '../ui/views/Admin/ProductDetail.vue'),
    meta: {
      layout: 'Admin',
    },
  },
  {
    path: '/bundles',
    component: () => import(/* webpackChunkName: "adminroutes" */ '../ui/views/Admin/Bundles.vue'),
    meta: {
      layout: 'Admin',
    },
  },
  {
    path: '/subscription-plans',
    component: () =>
      import(/* webpackChunkName: "adminroutes" */ '../ui/views/Admin/SubscriptionPlans.vue'),
    meta: {
      layout: 'Admin',
    },
  },
  {
    path: '/:path(.*)',
    redirect: defaultRoute,
  },
];

export default routes;
