import feathers, { Application } from '@feathersjs/feathers';
import io from 'socket.io-client';
import socketio from '@feathersjs/socketio-client';
import { authClient } from './authClient';

// eslint-disable-next-line import/no-mutable-exports
let wsClient: Application<any> | null = null;

if (process.env.VUE_APP_MODE === 'admin') {
  // @ts-ignore
  const socket = io(process.env.VUE_APP_API_URL, {
    transports: ['websocket', 'polling'],
    forceNew: true,
  });
  wsClient = feathers();

  wsClient.configure(socketio(socket, { timeout: 50000 }));
  wsClient.configure(authClient);
}

export { wsClient };
