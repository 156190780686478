import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  breakpoint: {
    scrollBarWidth: 24,
  },
});

// if (process.env.VUE_APP_MODE === 'admin') {
//   console.log('init');
//   const init = () => {
//     // @ts-ignore
//     import(/* webpackChunkName: "vuetify" */'vuetify').then((Vuetify) => {
//       // @ts-ignore
//       Vue.use(Vuetify);
//     });
//     // @ts-ignore
//     import(/* webpackChunkName: "vuetify" */'vuetify/dist/vuetify.min.css');
//   };
//   init();
// }
