import Vue from 'vue';
import { App, router, i18n, store } from './all';
import vuetify from './plugins/vuetify';
import './plugins/currency-input';
import './mainAdmin.css';

Vue.filter('formatRole', (role: string) => {
  switch (role) {
    case 'admin':
      return 'Admin';
    case 'registeredUser':
      return 'Registrierter User';
    case 'verifiedUser':
      return 'Verifizierter User';
    case 'adultConfirmed':
      return 'Bestätiger Erwachsener';
    case 'subscriber':
      return 'Subscriber';
    default:
      return '';
  }
});

Vue.filter('prettyBytes', (num: number) => {
  const neg = num < 0;
  const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return `${neg ? '-' : ''}${num} 'B'`;
  }

  const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
  num = <any>(num / 1000 ** exponent).toFixed(2) * 1;
  const unit = units[exponent];

  return `${neg ? '-' : ''}${num} ${unit}`;
});

new Vue({
  router,
  i18n,
  store,
  // @ts-ignore
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
